import { getConsentListing } from '../../requests/getConsentListing';
/**
 * Since consent-searches runs on the second to last step, if there are serviceSpecificAttributes on that step, we need to listen for a change
 */
export function rerunConsentSearchesOnInputChange() {
	window.modForm.getCurrentStep()?.querySelectorAll(window.modForm.opts.consentCaptureInputChangeSelector)?.forEach((element) => {
		element.addEventListener('input', function(e) {
			e.stopImmediatePropagation();
			getConsentListing();
		});
	});
}
