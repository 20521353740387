/**
 * Mapped service codes and their required attributes
 * @type {Array}
 */
export const serviceCodesAndAttributions = [
	{
		serviceCodes: ['ALERTS_MEDICAL'],
		requiredAttributes: ['MedicalAlertProtectionAt', 'WhoseMedicalAlert', 'HasLandlineAtHome']
	},
	{
		serviceCodes: ['BASEMENT_WATERPROOFING'],
		requiredAttributes: ['OwnHome']
	},
	{
		serviceCodes: ['BATH_REMODEL'],
		requiredAttributes: ['BathProjectScope', 'OptIn1', 'OwnHome']
	},
	{
		serviceCodes: ['BATHROOM_REFACING'],
		requiredAttributes: ['BathProjectScope', 'OwnHome']
	},
	{
		serviceCodes: ['CONCRETE_FOUNDATION'],
		requiredAttributes: ['ConcreteProjectScope', 'OwnHome']
	},
	{
		serviceCodes: ['CABINET_INSTALL', 'CABINET_REFACING'],
		requiredAttributes: ['OwnHome']
	},
	{
		serviceCodes: ['COUNTERTOP'],
		requiredAttributes: ['CountertopType', 'CountertopProjectScope', 'CommercialLocation', 'BuyTimeframe', 'OwnHome']
	},
	{
		serviceCodes: ['DOOR'],
		requiredAttributes: ['DoorProjectScope', 'DoorsNumber', 'DoorType', 'OwnHome']
	},
	{
		serviceCodes: ['DRAINS', 'SEWER'],
		requiredAttributes: ['DrainageIssue', 'OwnHome']
	},
	{
		serviceCodes: ['FLOORING_HARDWOOD', 'FLOORING_TILE', 'FLOORING_VINYL_LINOLEUM', 'FLOORING_LAMINATE', 'FLOORING_SUBFLOORS', 'CARPET'],
		requiredAttributes: ['CommercialLocation', 'FlooringProjectScope', 'MaterialPurchase', 'OwnHome']
	},
	{
		serviceCodes: ['GARAGE_DOOR'],
		requiredAttributes: ['GarageDoorSize', 'OwnHome', 'ProjectScope']
	},
	{
		serviceCodes: ['GUTTER_COVERS'],
		requiredAttributes: ['CommercialLocation', 'OwnHome']
	},
	{
		serviceCodes: ['HOME_SECURITY'],
		requiredAttributes: ['CommercialLocation', 'HomeSecurityProjectScope', 'Monitoring', 'SecuritySystemInstallationType']
	},
	{
		serviceCodes: ['HOME_WARRANTY'],
		requiredAttributes: ['Interest', 'OwnHome']
	},
	{
		serviceCodes: ['HOT_TUBS'],
		requiredAttributes: ['HotTubUse', 'HotTubType', 'OwnHome']
	},
	{
		serviceCodes: ['HVAC'],
		requiredAttributes: ['HVACProjectScope', 'HVACInterest', 'OwnHome']
	},
	{
		serviceCodes: ['INSULATION'],
		requiredAttributes: ['OwnHome', 'SubProductService']
	},
	{
		serviceCodes: ['KITCHEN_REMODEL'],
		requiredAttributes: ['OptIn', 'OwnHome']
	},
	{
		serviceCodes: ['PLUMBING'],
		requiredAttributes: ['OwnHome', 'PlumbingProjectScope']
	},
	{
		serviceCodes: ['ROOFING_ASPHALT', 'ROOFING_COMPOSITE', 'ROOFING_CEDAR_SHAKE', 'ROOFING_METAL', 'ROOFING_NATURAL_SLATE', 'ROOFING_TILE'],
		requiredAttributes: ['OwnHome', 'RoofingPlan']
	},
	{
		serviceCodes: ['SIDING_VINYL', 'SIDING_ALUMINIUM', 'SIDING_BRICKFACE', 'SIDING_COMPOSITE_WOOD', 'SIDING_STONEFACE', 'STUCCO_EXTERIOR'],
		requiredAttributes: ['OwnHome', 'ProjectPlan']
	},
	{
		serviceCodes: ['SOLAR'],
		requiredAttributes: ['ElectricBill', 'OwnHome', 'UtilityProvider']
	},
	{
		serviceCodes: ['STAIR_LIFTS'],
		requiredAttributes: ['OwnHome', 'ReasonForStairLift']
	},
	{
		serviceCodes: ['TREE_SERVICES'],
		requiredAttributes: ['CommercialLocation', 'JobType', 'NumberOfTrees', 'OwnHome', 'TreeSize']
	},
	{
		serviceCodes: ['WALK_IN_TUBS', 'WALK_IN_SHOWERS'],
		requiredAttributes: ['Interest', 'OwnHome']
	},
	{
		serviceCodes: ['WATER_HEATERS'],
		requiredAttributes: ['ProjectScope', 'OwnHome']
	},
	{
		serviceCodes: ['WATER_MAIN'],
		requiredAttributes: ['OwnHome']
	},
	{
		serviceCodes: ['WATER_TREATMENT'],
		requiredAttributes: ['BuyTimeframe', 'OwnHome', 'ServiceType']
	},
	{
		serviceCodes: ['WINDOWS'],
		requiredAttributes: ['NumberOfWindows', 'OwnHome', 'WindowsProjectScope']
	}
];
