import {
	addFallbackOwnHome,
	addGeolocationData,
	getCrossSellApiDomain,
	getHeapSessionIdCookieData,
	getQSApiDomain,
	initDuplicateSubmissionExperience,
	processQSFormSubmitSuccess,
	showNextStep,
	swapRecaptchaTokenWithFlag,
	switchFormLoadingState,
	switchFormSubmitState,
	useInitialQueryParamsFromSessionStorage,
	initSentryScript
} from '../../helpers';
/**
 * Submit form data to Quinstreet API endpoint
 * @param {Object} formData - form data
 * @param {Function} successCallback - success callback
 * @param {Function} errorCallback - error callback
 */
export function submitFormDataToQSApi(formData, successCallback, errorCallback) {
	const apiDomain = window.modForm.opts.isCrossSellForm ? getCrossSellApiDomain() : getQSApiDomain();
	const apiUrl = apiDomain + window.modForm.opts.leadSubmitApi;

	// Check if the formdata has UtilityProvider, if its present encode its value
	if (formData.UtilityProvider) {
		formData = window.modUtils.encodeValueInObject(formData, 'UtilityProvider');
	}

	// Add fallback OwnHome value if needed
	formData = addFallbackOwnHome(formData);

	// Remove recaptcha token string and add flag
	formData = swapRecaptchaTokenWithFlag(formData);

	// If user encounters JS error and session refreshes, send initial query params & clean up form data
	formData = useInitialQueryParamsFromSessionStorage(formData);

	// Add Geolocation Data as a part of WEBP-1379 to measure the accuracy of Geolookup endpoint
	formData = addGeolocationData(formData);

	window.modForm.opts.formData = formData;

	if (window.modForm.isSubmitting === false) {
		window.modForm.isSubmitting = true;
		let response = {};

		const request = new XMLHttpRequest();
		request.open('POST', apiUrl);
		request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		request.send(new URLSearchParams(Object.entries(formData)).toString());
		request.addEventListener('load', function() {
			const xhr = this;
			if (this.response) {
				response = { ...response, ...JSON.parse(this.response) };
				if (this.readyState === 4 && this.status === 200) {
					document.body.classList.add('prevent-abandonment');
					if ('function' === typeof successCallback) {
						successCallback(response);
					}

					if (response.RESPONSE.CommissionDetails) {
						if (response.RESPONSE.CommissionDetails.firePixel === true) {
							window.modForm.opts.isFirePixelTrue = true;
						}
					}

					if (response.RESPONSE && formData.PC) {
						response.RESPONSE.zip = formData.PC;
					}

					if (response.RESPONSE && formData.SP) {
						response.RESPONSE.state = formData.SP;
					}

					if (response.RESPONSE && formData.CT) {
						response.RESPONSE.city = formData.CT;
					}

					// Redshift Transforms that normally happened on the TYP/Submit Success
					if (response.RESPONSE.DCK) {
						const dataCaptureKey = response.RESPONSE.DCK;

						window.Modalytics.heap('addEventProperties', {
							/* eslint-disable camelcase */
							project_id: dataCaptureKey,
							form_path: window.modForm.opts.landingPage,
							DCK: dataCaptureKey
							/* eslint-enable camelcase */
						});
					}

					// E-Commerce Matching Logic (Exclusive = Redirect / Widget, Shared = HRC Secondary Button Addition)
					if (response.RESPONSE.MATCHES.length > 0) {
						const exclusiveMatchKeys = Object.values(window.modForm.opts.eCommerceVendorKeys.exclusive),
							exclusiveMatch = response.RESPONSE.MATCHES[0],
							exclusiveVendorKey = exclusiveMatch.KEY,
							sharedMatchKeys = Object.values(window.modForm.opts.eCommerceVendorKeys.shared),
							isSharedMatch = response.RESPONSE.MATCHES.filter(match => sharedMatchKeys.includes(match.KEY));

						if (response.RESPONSE.MATCHES.length === 1 && exclusiveMatchKeys.includes(exclusiveVendorKey)) {
							// load sentry script
							initSentryScript();
							const sentryLogLevel = 'error';

							if (exclusiveMatch.clientDetails) {
								const matchedVendor = Object.keys(window.modForm.opts.eCommerceVendorKeys.exclusive).find(key => window.modForm.opts.eCommerceVendorKeys.exclusive[key] === exclusiveVendorKey);
								window.modForm.opts.exclusiveMatchVendor = matchedVendor;

								if (exclusiveMatch.clientDetails.leadid || exclusiveMatch.clientDetails.PewcUrl) {
									window.modForm.opts.isExclusiveMatch = true;

									if (exclusiveVendorKey !== window.modForm.opts.eCommerceVendorKeys.exclusive.frontdoor) {
										window.Sentry?.captureMessage('E-Commerce Widget | Not matched with frontdoor', sentryLogLevel);
									}

									if (exclusiveMatch.clientDetails.leadid && exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.frontdoor) {
										const frontdoorBasesUrl = 'https://shopping.ahs.com/plans/get-my-quote/select?leadData=',
											frontdoorUtmSource = '&utm_medium=affiliate&utm_source=modernize&utm_campaign=modernize_ecomm';

										window.modForm.opts.frontdoorLeadId = exclusiveMatch.clientDetails.leadid ? exclusiveMatch.clientDetails.leadid : '';

										if (response.RESPONSE.city && response.RESPONSE.state) {
											window.modForm.opts.userCity = response.RESPONSE.city;
											window.modForm.opts.userState = response.RESPONSE.state;
										} else if (window.modForm.opts.userLocation) {
											const userLocation = window.modForm.opts.userLocation.split(',');
											window.modForm.opts.userCity = userLocation[0].trim();
											window.modForm.opts.userState = userLocation[1].trim();
										}

										const piiData = `;;${window.modForm.opts.userCity};${window.modForm.opts.userState};${response.RESPONSE.zip};${window.modForm.opts.formData.S1};;;;;;;${window.modForm.opts.frontdoorLeadId};`;
										const piiEncoded = btoa(piiData);

										window.modForm.opts.exclusiveMatchResultsPage = `${frontdoorBasesUrl}${piiEncoded}${frontdoorUtmSource}`;
									} else if (exclusiveMatch.clientDetails.PewcUrl) {
										window.modForm.opts.exclusiveMatchResultsPage = exclusiveMatch.clientDetails.PewcUrl;
									}

									window.Modalytics.heap('track', ['E-Commerce Flow', {
										companyName: exclusiveMatch.DISPLAYNAME,
										destinationLink: window.modForm.opts.exclusiveMatchResultsPage,
										matchCriteria: 'Exclusive (Redirect)'
									}]);
								} else if ((exclusiveMatch.clientDetails.ClientPingToken || exclusiveMatch.clientDetails.widgetUrl || exclusiveMatch.clientDetails.ConsentIntegrationDetails) && !window.modForm.opts.includesConfirmationStep) {
									window.modForm.opts.isExclusiveWidgetMatch = true;

									let responseToken = '';

									const confirmationStep = window.modForm.opts.form.querySelector('.step--confirmation'),
										widgetStep = document.createElement('li');

									widgetStep.className = 'swiper-slide swiper-no-swiping step step--show-widget';
									widgetStep.setAttribute('aria-hidden', 'true');
									widgetStep.setAttribute('tabindex', '-1');
									widgetStep.setAttribute('data-bind', 'step');
									widgetStep.setAttribute('data-step-name', 'widget');

									const stepInner = document.createElement('div');
									stepInner.className = 'step-inner';

									widgetStep.appendChild(stepInner);

									// Needed while Triple TCPA is still being tested (removing the step if not a variation)
									if (confirmationStep) {
										confirmationStep.remove();
									}

									window.modForm.opts.form.querySelector('.swiper-wrapper').appendChild(widgetStep);
									widgetStep.setAttribute('id', `step${window.modForm.opts.form.querySelectorAll(window.modForm.opts.stepsSelector).length + 1}`);
									window.modForm.opts.formSwiperInstance.update();

									const loaderLink = document.querySelector('.loader__link');
									if (loaderLink) {
										loaderLink.style.display = 'none';
									}

									if (exclusiveMatch.clientDetails.ConsentIntegrationDetails && exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.airo) {
										responseToken = JSON.stringify(exclusiveMatch.clientDetails.ConsentIntegrationDetails);

										if (exclusiveMatch.clientDetails.ConsentIntegrationDetails.trade && exclusiveMatch.clientDetails.ConsentIntegrationDetails.campaignId) {
											const targetContainer = document.createElement('div'),
												initializeScript = document.createElement('script');

											targetContainer.id = 'airo-anchor';
											stepInner.appendChild(targetContainer);
											stepInner.style.overflow = 'auto';

											window.airoBookingForm = {
												zipCode: formData.PC,
												trade: exclusiveMatch.clientDetails.ConsentIntegrationDetails.trade,
												firstName: formData.FN,
												lastName: formData.LN,
												address: formData.S1,
												email: formData.EM,
												phone: formData.HP,
												campaignId: exclusiveMatch.clientDetails.ConsentIntegrationDetails.campaignId,
												apptScheduledCallback: function() {
													window.Modalytics.heap('track', ['E-Commerce Flow | Summary Shown']);
												},
												doneCallback: function() {
													window.Modalytics.heap('track', ['E-Commerce Flow | Completed']);
													window.location = window.modForm.opts.resultsPage;
												}
											};

											initializeScript.src = `https://form.contractorappointments.com/js/embed-form.js?v=${new Date().getTime()}`;
											document.body.appendChild(initializeScript);
										}

									} else {
										responseToken = exclusiveMatch.clientDetails.ClientPingToken ? exclusiveMatch.clientDetails.ClientPingToken : exclusiveMatch.clientDetails.widgetUrl;

										if (!exclusiveMatch.clientDetails.ClientPingToken) {
											window.Sentry?.captureMessage('E-Commerce Widget | Undefined ClientPingToken', sentryLogLevel);
										}

										if (exclusiveVendorKey !== window.modForm.opts.eCommerceVendorKeys.exclusive.angi) {
											window.Sentry?.captureMessage('E-Commerce Widget | Not matched with Angi', sentryLogLevel);
										}

										if (exclusiveMatch.clientDetails.ClientPingToken && exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.angi) {
											const targetContainer = document.createElement('div'),
												initializeScript = document.createElement('script'),
												scriptUrl = 'https://request.angi.com/hcc/dropin-112a615a05e36545.js';

											targetContainer.className = 'dropin-container';
											stepInner.appendChild(targetContainer);

											fetch(scriptUrl)
												.then(angiResp => {
													if (!angiResp.ok) {
														window.Sentry?.captureMessage(`E-Commerce Widget | Script Response Failed ${scriptUrl} | ${responseToken}`, sentryLogLevel);
														window.Modalytics.heap('track', ['E-Commerce Widget | Script Error', {
															error: `Response Error: ${angiResp.status} ${angiResp.statusText}`
														}]);
													}

													initializeScript.src = scriptUrl;
													initializeScript.onload = function() {
														if (window.initializeDropIn) {
															window.initializeDropIn(responseToken);
															window.Modalytics.heap('track', ['E-Commerce Widget | Initialize Drop In Successful']);
														} else {
															window.Sentry?.captureMessage(`E-Commerce Widget | Undefined initializeDropIn | ${responseToken}`, sentryLogLevel);
														}
													};
													document.body.appendChild(initializeScript);
												})
												.catch(angiErr => {
													window.Sentry?.captureMessage(`E-Commerce Widget | Script Error ${scriptUrl} | ${responseToken}`, sentryLogLevel);
													window.Modalytics.heap('track', ['E-Commerce Widget | Script Error', {
														error: `Fetch Error: ${angiErr}`
													}]);
												});
										} else if (exclusiveMatch.clientDetails.widgetUrl) {
											const iframe = document.createElement('iframe');

											iframe.src = responseToken;
											stepInner.appendChild(iframe);
										}
									}

									window.modForm.opts.autoRedirectToTyOrSorryPage = false;
									switchFormLoadingState(false);
									switchFormSubmitState(false);
									showNextStep();
									widgetStep.setAttribute('aria-hidden', 'false');

									if (window.modForm.opts.backButtons) {
										window.modForm.opts.backButtons.forEach(function(backButton) {
											backButton.style.display = 'none';
										});
									}
									if (window.modForm.opts.progressBar) {
										window.modForm.opts.progressBar.forEach(function(bar) {
											bar.style.display = 'none';
										});
									}

									window.Modalytics.heap('track', ['E-Commerce Widget', {
										companyName: exclusiveMatch.DISPLAYNAME,
										pingToken: responseToken,
										matchCriteria: 'Exclusive (Widget)'
									}]);

									// Listen for when the widget has been completed (contains both listeners from Angi / Networx)
									window.addEventListener('message', function(event) {
										const data = event.data;

										if (!data) {
											window.Sentry?.captureMessage(`E-Commerce Widget | Undefined event.data | ${exclusiveMatch.DISPLAYNAME} | ${responseToken}`, sentryLogLevel);
										}

										if (exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.angi || exclusiveVendorKey === window.modForm.opts.eCommerceVendorKeys.exclusive.networx) {
											if (data && (data.event === 'pageState' && data.payload === 'WidgetLoaded') || (data.source === 'networx' && data.payload && data.payload.pageLoad)) {
												window.Modalytics.heap('track', ['E-Commerce Widget | Loaded', {
													companyName: exclusiveMatch.DISPLAYNAME,
													message: JSON.stringify(data)
												}]);
											}
										}

										if (data && (data.event === 'pageState' && data.payload === 'ThankYou') || (data.source === 'networx' && data.payload && data.payload.leadDone)) {
											window.Modalytics.heap('track', ['E-Commerce Widget | Completed', {
												resultsPage: window.modForm.opts.resultsPage
											}]);
											history.pushState(null, null, `${window.modForm.opts.fullPageUrl}&refreshExp=true`);
											window.location = window.modForm.opts.resultsPage;
										}
									});
								}
							}
						} else if (response.RESPONSE.MATCHES.length >= 1 && isSharedMatch.length > 0) {
							const matchingKeys = isSharedMatch.map(match => match.KEY).join(',');
							window.modForm.opts.sharedParam = `shared=${matchingKeys}`;
							window.modForm.opts.isSharedMatch = isSharedMatch;
						}
					}

					// Change form submit state
					if (window.modForm.opts.showLoadingOverlay && !window.modForm.opts.isExclusiveWidgetMatch) {
						switchFormSubmitState('submitted');
						setTimeout(function() {
							processQSFormSubmitSuccess(response, xhr);
						}, 1000);
					} else {
						processQSFormSubmitSuccess(response, xhr);
					}
				} else {
					if (typeof window.modForm.opts.onGetMatchesBlocked === 'function' && this.status === 0) {
						// Status code 0 most common cause: IP Blocked
						window.modForm.opts.onGetMatchesBlocked();
					}
					// If there was an error, we first look for any error callback first
					if (typeof errorCallback === 'function') {
						errorCallback(xhr, this.status, response);
					}
				}
			}
		});

		request.addEventListener('loadend', function() {
			window.modForm.isSubmitting = false;
			switchFormLoadingState(false);
		});

		request.addEventListener('error', function() {
			console.error('Something went wrong!');

			const $loader = document.querySelector('.loader');
			if ($loader) {
				$loader.style.opacity = 0;
				$loader.style.zIndex = -1;
			}

			// Show duplicate submission fallback experience if API encounters network issues
			const heapSessionIdCookieData = getHeapSessionIdCookieData();
			initDuplicateSubmissionExperience(heapSessionIdCookieData);
			window.modUtils.heap('track', ['API Error | Showing Fallback Experience', { function: 'submitFormDataToQSApi' }]);
		});
	}
}
