import {
	getFormData,
	serviceCodesAndAttributions
} from '../../helpers';

/**
 * This function maps the serviceSpecificAttributes based on current trade
 * @param {String} serviceCode - serviceCode
 */
export function mapServiceSpecificAttributes(serviceCode) {
	const currentFormData = getFormData();

	function getAttributeValue(elementName) {
		if (window.modForm.opts.form && elementName) {
			switch (elementName) {
			case 'OwnHome': {
				const ownHomeElement = document.querySelector(`[name=${elementName}]`);
				if (ownHomeElement && ownHomeElement.type === 'checkbox') {
					window.modForm.opts.serviceSpecificAttributes[elementName] = ownHomeElement.checked ? 'Yes' : 'No';
				} else {
					window.modForm.opts.serviceSpecificAttributes[elementName] = currentFormData.OwnHome === 'Authorized' ? 'Yes' : currentFormData.OwnHome;
				}
				break;
			}
			case 'CommercialLocation':
				window.modForm.opts.serviceSpecificAttributes.CommercialLocation = currentFormData.CommercialLocation === 'Homerent' ? 'Home' : currentFormData.CommercialLocation;
				if (serviceCode === 'HOME_SECURITY' && (!currentFormData.OwnHome || currentFormData.OwnHome.trim() === '')) {
					window.modForm.opts.serviceSpecificAttributes.OwnHome = currentFormData.CommercialLocation !== 'Homerent' ? 'Yes' : 'No';
				}
				break;
			case 'HVACProjectScope':
				window.modForm.opts.serviceSpecificAttributes.HVACProjectScope = (currentFormData.HVACProjectScope === 'Repair' && currentFormData.ReadyToReplace === 'Yes') ? 'Install' : currentFormData.HVACProjectScope;
				break;
			case 'HVACInterest':
				window.modForm.opts.serviceSpecificAttributes.HVACInterest = currentFormData.HVACInterest.replace(' Heat Pump', '');
				if (['Central Heating', 'Boiler/Radiator'].includes(window.modForm.opts.serviceSpecificAttributes.HVACInterest)) {
					const system = window.modForm.opts.serviceSpecificAttributes.HVACInterest.replace(' ', '').replace('/Radiator', 'System');
					window.modForm.opts.serviceSpecificAttributes[`${system}${window.modForm.opts.serviceSpecificAttributes.HVACProjectScope}Type`] = currentFormData.BoilerHeatingInstallRepairType;
				}
				window.modForm.opts.serviceSpecificAttributes.HVACInterest = `${window.modForm.opts.serviceSpecificAttributes.HVACProjectScope} ${window.modForm.opts.serviceSpecificAttributes.HVACInterest}`;
				break;
			case 'NumberOfWindows':
				window.modForm.opts.serviceSpecificAttributes.NumberOfWindows = (currentFormData.NumberOfWindows === '1' && currentFormData.MultipleWindows === 'Yes') ? '3-5' : currentFormData.NumberOfWindows;
				break;
			case 'ConcreteProjectScope':
				window.modForm.opts.serviceSpecificAttributes.ConcreteProjectScope = (currentFormData.service === 'CONCRETE_FOUNDATION' ? 'Repair' : currentFormData.ConcreteProjectScope);
				break;
			default:
				if (!currentFormData[elementName]) {
					return;
				}
				window.modForm.opts.serviceSpecificAttributes[elementName] = currentFormData[elementName];
			}
		}
	}

	for (const service of serviceCodesAndAttributions) {
		if (service.serviceCodes.includes(serviceCode)) {
			if (Object.keys(window.modForm.opts.serviceSpecificAttributes).length) {
				window.modForm.opts.serviceSpecificAttributes = {};
			}
			service.requiredAttributes.forEach(attribute => {
				getAttributeValue(attribute);
			});
		}
	}
}
