import {
	getFormData,
	getFormsServiceApiDomain,
	mapDynamicServiceCode,
	mapServiceSpecificAttributes,
	getTcpaElementContent,
	appendFormInput
} from '../../helpers';
/**
 * Submit trade-specific answers to HS Forms Service to get Consent Vendor Listings
 * @param {function} callback - Allows us to run a callback function & grab the response
 */
export function getConsentListing(callback) {
	window.modForm.opts.gettingLiveConsent = true;

	const apiDomain = getFormsServiceApiDomain(),
		apiUrl = apiDomain + 'forms/consent-searches',
		providersWrapper = window.modForm.opts.form.querySelector('.tcpa-providers'),
		targetDiv = window.modForm.opts.form.querySelector('[data-providers-consent]'),
		formData = getFormData(),
		vwo = {};

	const lowerCasedQuadLinkData = Object.fromEntries(
		Object.entries(window.modForm.opts.quadLinkParams).map(([key, value]) => [key.toLowerCase(), value])
	);

	const quadLinkData = window.modForm.opts.quadLinkData.quadlink ? { 'quadLink': window.modForm.opts.quadLinkData.quadlink } : lowerCasedQuadLinkData,
		service = mapDynamicServiceCode(formData),
		partnerSourceID = formData.PartnerSourceID ? { partnerSourceID: formData.PartnerSourceID } : {};

	mapServiceSpecificAttributes(service);

	if (window._vwo_campaignData && Object.keys(window._vwo_campaignData).length !== 0) {
		vwo.vwoTestId = Object.keys(window._vwo_campaignData)[0];
		vwo.vwoVariationName = window._vwo_campaignData[vwo.vwoTestId].n;
	}

	const consentSearchData = {
		'zip': window.modForm.opts.getVars.zip ? window.modForm.opts.getVars.zip : window.modForm.opts.zipCode,
		'service': service,
		'site': window.modForm.opts.quadLinkData.WebSiteName?.replace(/^www\./, ''),
		...quadLinkData,
		...partnerSourceID,
		'serviceSpecificAttributes': {
			...window.modForm.opts.serviceSpecificAttributes
		},
		'searchContext': {
			'searchContext': 'O&O Web Pre Submit',
			'landingPageUrl': window.location.href.split('#')[0],
			...vwo
		}
	};

	if (JSON.stringify(consentSearchData) === window.modForm.opts.consentCaptureDetails) {
		return;
	}

	window.modForm.opts.consentCaptureDetails = JSON.stringify(consentSearchData);

	let response = {};
	const request = new XMLHttpRequest();
	request.open('POST', apiUrl);
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(JSON.stringify(consentSearchData));
	request.addEventListener('load', function() {
		if (this.response) {
			response = { ...response, ...JSON.parse(this.response) };
			if (this.readyState === 4 && this.status === 200) {
				if (response.data.length === 1 && response.data[0].vendorDisplayName === 'Modernize') {
					// If Modernize is the only response – remove the "Providers" section
					if (providersWrapper) {
						providersWrapper.style.display = 'none';
					}
				} else {
					if (providersWrapper && providersWrapper.style.display === 'none') {
						providersWrapper.style.display = 'inline';
					}
				}

				if (response.requestId) {
					window.modForm.opts.consentRequestId = response.requestId;
				}

				if (JSON.stringify(response)) {
					if (targetDiv.hasChildNodes()) {
						while (targetDiv.firstChild) {
							targetDiv.removeChild(targetDiv.firstChild);
						}
					}

					window.modUtils.renderConsentCheckboxes(response.data, targetDiv, window.modForm.opts.fetchAndFormatConsentListings || window.modForm.opts.formatConsentCaptureVendors);

					if (window.modForm.opts.fetchAndFormatConsentListings || window.modForm.opts.sendConsentCaptureTcpaToLead) {
						const tcpaCopyField = window.modForm.opts.tcpaCopy && window.modForm.opts.tcpaCopy.length ? Array.from(window.modForm.opts.tcpaCopy).map(tcpa => getTcpaElementContent(tcpa).trim().replace(/(<([^>]+)>)/ig, '').replace(/\n/g, '').replace(/[\u00A0]+/g, '')) : '';
						const fieldsToBeUpdated = {
							tcpaText: tcpaCopyField,
							HomePhoneConsentLanguage: tcpaCopyField
						};
						Object.keys(fieldsToBeUpdated).forEach((key) => {
							appendFormInput(key, fieldsToBeUpdated[key]);
						});
					}
				}

				if (callback) {
					callback(response);
				}
			}
		}
	});

	request.addEventListener('error', function() {
		console.error('Something went wrong!');
	});
}
